import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from '../components/seo';

const Indywidualna = () => {
    return (
        <Layout>
            <Seo title="Oferta Indywidualna"/>
            <section className="subof-container">
                <section className="subof-intro">
                    <StaticImage 
                        src='../images/main_first.jpg'
                        alt="Maszyna do ćwiczeń Pilates" 
                        loading="eager"
                        className='subof-intro_picture'
                    />
                    <h1 className="subof-intro_title">Zajęcia Indywidualne</h1>
                    <h2 className="subof-intro-subtitle">Diagnostyka i treningi indywidualne na maszynach Pilates</h2>
                </section>
                <section className="subof-hero">
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text bold">Konsultacja diagnostyczna</p>
                        <p className="subof-hero_text">Specjalizujemy się w indywidualnych zajęciach metodą Pilates, czyli w zajęciach dopasowanych w 100% do danego organizmu. Pracujemy na układzie nerwowym, oddechowym i ruchowym (oraz w konsekwencji tego też na układzie hormonalnym). Pracujemy zatem bardzo kompleksowo.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Na początku każdego cyklu terapeutycznego metodą Pilates przeprowadzona jest indywidualna konsultacja diagnostyczna.<br/>Celem konsultacji jest poznanie stanu danego organizmu oraz jego gotowości do aktywności fizycznej, ewaluacja pracy kluczowych grup mięśniowych oraz postawienie celów, które Klient może osiągnąć dzięki pracy metodą Pilates.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Pełna, godzinna sesja diagnostyczna zaczyna się od przeprowadzenia krótkiego wywiadu w odwołaniu się do uzupełnionego przez Klienta kwestionariusza historii zdrowia i stylu życia. Następnie przeprowadzamy analizę wzrokową postawy ciała, analizę palpacyjną ważnych grup mięśniowych i stawów, po czym przechodzimy do wykonania podstawowych, dynamicznych testów biomechanicznych i neurologicznych, żeby zobaczyć jak ciało pracuje, w jakiej jest formie, jakie są ewentualne przeciążenia, zakresy ruchów, jaka jest świadomość ciała i równowaga.<br/>
                        Dzięki takiej sesji konsultacyjnej, Klient dostaje informacje, na jakich celach w pracy z ciałem powinien się skupić, aby poprawić postawę ciała, przywrócić równowagę mięśniową, zwiększyć swoją elastyczność, czy pozbyć się bólu i przywrócić ciału funkcjonalne zakresy. 
                        <br/>My natomiast dokładnie wiemy, jaki jest stan danej osoby i w oparciu o diagnostykę ustalamy cele treningów podsumowując wszystkie nasze ustalenia na Karcie Klienta.
                        </p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text bold">Treningi</p>
                        <p className="subof-hero_text">Program treningów przygotowany jest w oparciu o wnioski z konsultacji diagnostycznej.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Indywidualne treningi Pilates przeprowadzane są na macie z małym sprzętem oraz na maszynach Pilatesowych. Pracujemy na sprzęcie najwyższej jakości.<br/><br/>
                        Pilates Reformer prowadzimy na maszynach : Clinical Reformer with Tower marki Balanced Body (USA) – jedyny na świecie Reformer do zajęć Pilates dla dzieci i młodzieży, a także Rialto Reformer marki Balanced Body (USA) oraz Elite Wood Reformer with Tower marki Elina (Hiszpania).<br/><br/>
                        Pozostałe nasze maszyny to : Wunda Chair marki Balanced Body (USA),Combo Chair  marki Balanced Body (USA), Cadillac marki Balanced Body (USA), Ladder Barrel East Coast marki Balanced Body (USA), Spine Corrector Clara Barrel Lite marki Balanced Body (USA), Ladder Barrel Elitemarki Elina (Hiszpania), Spine Corrector marki Elina (Hiszpania).
                        </p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Sesje Pilatesu terapeutycznego powinny odbywać się regularnie. Częstotliwość zależy oczywiście od chęci i możliwości Klienta. Indywidualne sesje mogą odbywać się raz lub dwa razy w tygodniu, można też proces zajęć Pilates uzupełniać „pracami domowymi” , zajęciami w duecie lub innymi bezpiecznymi zajęciami ruchowymi. Jeżeli ktoś preferuje podejście intensywne dla lepszego i wcześniej zauważalnego efektu, rekomendowane są zajęcia indywidualne dwa razy w tygodniu. Pierwsza wyraźna zmiana w postawie ciała i w sposobie jego funkcjonowania widoczna jest najczęściej już na piątej lub szóstej sesji!</p>
                    </div>
                    <div className="sub-hero_link-container">
                        <Link to="/zapisy" className="sub-hero_link-link">ZAPISZ SIĘ</Link>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default Indywidualna;